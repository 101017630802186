import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { GiVacuumCleaner } from "react-icons/gi";
import { BsFillHousesFill } from "react-icons/bs";
import { FaPenFancy } from "react-icons/fa";
import { fetchSearchXMLResults } from "../Utils/SearchApiXML";

function UsrDetails() {
  const location = useLocation();
  const { rowData } = location.state || {};

  useEffect(() => {}, [rowData]); // Assurez-vous de mettre rowData dans les dépendances du useEffect si vous l'utilisez à l'intérieur

  const handleBeds24Click = async (event) => {
    //event.preventDefault();

    let params = `<request><auth><username>${rowData.userNameBeds24}</username><password>${rowData.apiKey}</password></auth></request>`;

    const results = await fetchSearchXMLResults(
      "https://beds24.com/api/xml/getProperties",
      params
    );

    console.log(results);
  };

  return (
    <div className="container">
      <Row xs={1} md={2} lg={4} className="g-4">
        <Col>
          <Card style={{ backgroundColor: "#ffd3e0" }}>
            <Card.Body>
              <Card.Title>
                <MdAccountCircle
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Full name
                </span>
                {rowData.isActive ? (
                  <span
                    style={{
                      color: "green",
                      fontSize: "1.5em",
                      marginLeft: "5px",
                    }}
                  >
                    &#8226;
                  </span>
                ) : (
                  <span
                    style={{
                      color: "red",
                      fontSize: "1.5em",
                      marginLeft: "5px",
                    }}
                  >
                    &#8226;
                  </span>
                )}
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>{rowData.description}</span>
              {rowData.parentId === null ? (
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <i>Owner</i>
                </span>
              ) : (
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  <i>Staff</i>
                </span>
              )}
              <span style={{ marginLeft: "1em" }}>({rowData.userId})</span>
              <br />
              <span style={{ marginLeft: "2em" }}>
                <a href={`mailto:${rowData.email}`}>{rowData.email}</a>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: "#a2cffe", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <CiCalendarDate
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Registration date
                </span>
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>{rowData.date}</span>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: "#b7e4c7", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <IoSettingsOutline
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Settings
                </span>
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>{rowData.key}</span>
              <br />
              <span style={{ marginLeft: "2em" }}>
                {rowData.userNameBeds24}
              </span>
              <br />
              <span style={{ marginLeft: "2em" }}>{rowData.apiKey}</span>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: "#d7aefb", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <GiVacuumCleaner
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>Staff</span>
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>
                {rowData.staffList ? rowData.staffList.split(",").length : 0}
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={4} className="g-4">
        <Col>
          <Card style={{ backgroundColor: "#ffd8b1", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <BsFillHousesFill
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Apartment count
                </span>
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>
                {rowData.staffList ? rowData.staffList.split(",").length : 0}
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: "#f7a399", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <FaPenFancy
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Subscription
                </span>
              </Card.Title>
              <span style={{ marginLeft: "2em" }}>{rowData.subLogDate}</span>
              <br />
              <span style={{ marginLeft: "2em" }}>{rowData.subProduct}</span>
              <br />
              <span style={{ marginLeft: "2em" }}>{rowData.subQuantity}</span>
              <br />
              <span style={{ marginLeft: "2em" }}>{rowData.subPrice}</span>
              <br />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ backgroundColor: "#bdfcc9", marginLeft: "5px" }}>
            <Card.Body>
              <Card.Title>
                <FaPenFancy
                  style={{
                    fontSize: "2em",
                    verticalAlign: "middle",
                    color: "white",
                  }}
                />
                <span style={{ color: "white", marginLeft: "5px" }}>
                  Beds24 connection
                </span>
              </Card.Title>
              <button onClick={() => handleBeds24Click()}>
                Check the connection
              </button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default UsrDetails;
