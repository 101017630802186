import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { fetchSearchResults } from "../Utils/SearchApi";
import { useNavigate } from "react-router-dom";

function SearchForm() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (event) => {
    event.preventDefault();

    const results = await fetchSearchResults(
      "https://mybusiness-bnb.fr/api/Admin/Search",
      searchTerm
    );

    setSearchResults(JSON.stringify(results));
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <div className="row justify-content-center mt-5">
        <div className="col-md-6 text-center">
          <form className="d-flex" onSubmit={handleSearch}>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Rechercher"
              aria-label="Search"
              value={searchTerm}
              onChange={handleInputChange}
            />
            <button className="btn btn-outline-primary" type="submit">
              <BiSearch />
            </button>
          </form>
        </div>
      </div>
      {searchResults.length > 0 && (
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col">
              <SearchResults results={searchResults} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SearchResults({ results }) {
  const navigate = useNavigate();

  if (results === undefined) {
    return null;
  }

  const resultDataAll = JSON.parse(results);
  const resultData = resultDataAll.table;

  const handleRowClick = (rowData) => {
    navigate(`/Pages/UserDetails/${rowData.userId}`, { state: { rowData } });
  };

  return (
    <table
      className="table table-bordered table-striped"
      style={{ width: "100%" }}
    >
      <thead>
        <tr>
          <th>UserId</th>
          <th>Key</th>
          <th>IsActive</th>
          <th>Email</th>
          <th>ParentId</th>
          <th>APIKeyBeds24</th>
          <th>Date</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {resultData.map((result, index) => (
          <tr key={index} onClick={() => handleRowClick(result)}>
            <td>{result.userId}</td>
            <td>{result.key}</td>
            <td>
              {result.isActive ? (
                <span style={{ color: "green" }}>&#8226;</span>
              ) : (
                <span style={{ color: "red" }}>&#8226;</span>
              )}
            </td>
            <td>{result.email}</td>
            <td>{result.parentId}</td>
            <td>{result.apiKey}</td>
            <td>{result.date}</td>
            <td>{result.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SearchForm;
