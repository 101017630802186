const fetchSearchXMLResults = async (url, searchTerm) => {
  const data = searchTerm; // Simplement la chaîne de recherche

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/xml",
      },
      body: data,
      redirect: "follow",
    });

    if (!response.ok) {
      alert("Erreur lors de la requête");
    }

    const responseData = await response.text(); // Récupérer la réponse en JSON

    // Gérer la réponse de l'API (peut-être traiter les résultats ici)
    return responseData; // Renvoyer les données reçues si nécessaire
  } catch (error) {
    console.error("Erreur lors de la recherche:", error);
    return []; // Renvoyer une valeur par défaut ou gérer l'erreur
  }
};

export { fetchSearchXMLResults };
