import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginForm({ handleLogin }) {
  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (login === "dave" && password === "Agnes02*") {
      handleLogin();
      navigate("/home"); // Redirection vers la page d'accueil
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <form onSubmit={handleSubmit} className="card">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Connexion</h2>
              {error && (
                <p className="text-danger mb-3 text-center">
                  Identifiants incorrects. Veuillez réessayer.
                </p>
              )}
              <div className="mb-3">
                <label htmlFor="login" className="form-label">
                  Login:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="login"
                  value={login}
                  onChange={handleLoginChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Mot de passe:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary">
                  Se connecter
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
