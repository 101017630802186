import React from "react";
import SearchForm from "../Components/SearchForm";

function Home() {
  return (
    <div className="container-fluid">
      <div className="row justify-content-center mt-5">
        <div className="col-12">
          <h2 className="text-center">Users search</h2>
          <SearchForm />
        </div>
      </div>
    </div>
  );
}

export default Home;
